import React from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBContainer, MDBRow, MDBModal, MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, 
   MDBInput, MDBForm, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardText, MDBAlert,MDBDatePickerV5 
} from 'mdbreact';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import $ from 'jquery';
// import { postDatatoDb } from '../../components/JSfunctions/Utils';

class FormSubmissionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      username:"",
      password:"",
      userId:"",
      dynamicError:"Looks like there was an error logging you in please try again",
    };
    this.onClick = this.onClick.bind(this);
  }

 
  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }
  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    console.log(event.target.value)
  };


  async componentDidMount() {
    
    $('#processingLoader').hide();
    $('#signUpButton').show();
    $('#newLoader').hide();
    $('#showdynamicError').hide();

  
    var x = localStorage.getItem("currentUser");
    var obj = JSON.parse(x);

    console.log("obj login");
    console.log(obj);

    if(obj !== null)
    {
      //redirect user
      window.location.href = "/UserHome";
    }


  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }


  login(){

    $('#processingLoader').show();
    $('#signUpButton').hide();

    const data = {
      username:this.state.username,
      password:this.state.password
    }
    var userRole = ""

    if(this.state.username === "" || this.state.password === "")
    {
      $('#processingLoader').hide();
      $('#signUpButton').show();
      $('#showdynamicError').show();
      this.setState({dynamicError:"Please ensure you have entered the Username and Password"});

      return;
    }


    axios.post(process.env.REACT_APP_API_ACTIVE_URL + "Login", data).then((response) => {
      //set current user
      localStorage.setItem('currentUser', JSON.stringify(response.data));
      localStorage.setItem('currentUserToken', response.data.token);


      var base64Url = response.data.token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      var newobj = JSON.parse(jsonPayload);

      for (let key in newobj) {
        if (key === "http://schemas.microsoft.com/ws/2008/06/identity/claims/role") {
          localStorage.setItem('currentUserRole', newobj[key]);
          userRole = newobj[key];
        }
        if (key === "exp") {
          try {              
            const expirationTime = newobj[key];
            const now = Date.now() / 1000; // convert to seconds
            
            if (now > expirationTime) {
              console.log('Token has expired');
              localStorage.removeItem("currentUser");
              localStorage.removeItem("currentUserRole");
            } else {
              console.log('Token is still valid');
            }
          } catch (error) {
            console.log('Invalid token');
            $('#processingLoader').hide();
            $('#signUpButton').show();
          }

      }
      }


      setTimeout(
        function() {
          if(userRole === "Admin")
          {
            window.location.href = "/";
          }
          else if(userRole == "User")
          {
            window.location.href = "/";
          }
          else{
            window.location.href = "/Login";
          }

          
        }
        .bind(this),
        2000
      );


    }).catch((error) => {
      // handle error
      console.log(error);
      $('#processingLoader').hide();
      $('#signUpButton').show();
      $('#showdynamicError').show();

      switch(error.code)
      {
          case "ERR_BAD_REQUEST":  this.setState({dynamicError:"Sorry there was an issue getting your details please try again"})
      }
      
    });
  }
  


  render() {
    const {dataPasssed} = this.state; 
    const {dynamicError} = this.state; 
    return (      
          <div > 
        <header>

          <MDBContainer id="signUp2" fluid className="mt-5 mb-5">
          <MDBRow>
              <MDBCol size="12">
                <p className="display-7 text-center text-uppercase">LOGIN</p>
                <hr className="divider" />
                <p id="showdynamicError" className="display-7 text-center text-uppercase redError">{dynamicError}</p>
              </MDBCol>
              
            </MDBRow>

            <MDBRow>            
              {/*Form Left Column*/}
              <MDBCol lg="4" md="12"/>
              <MDBCol lg="4" md="12">
                <div className="dark-grey-text">
                <MDBInput
                    placeholder="Email Address"
                    icon="signature"
                    type="text"
                    name="username"
                    id="username"
                    value={this.state.username}
                    onChange={this.changeHandler}
                    className="form-control"
                    required
                    // pattern="^[_a-z A-Z]{3,}"
                    outline
                  />
                </div>
                </MDBCol>
              </MDBRow>

              <MDBRow>
              {/*Form Left Column*/}
              <MDBCol lg="4" md="12"/>
              <MDBCol lg="4" md="12">
                <div className="dark-grey-text">
                <MDBInput
                    label="Password"
                    icon="unlock-alt"
                    type="password"
                    name="password"
                    maxLength="50"
                    id="password"
                    value={this.state.password}
                    onChange={this.changeHandler}
                    className="form-control"
                    required
                   // pattern="^[_a-z A-Z]{3,}"
                    outline
                  />
                </div>
                </MDBCol>
              </MDBRow>


              <MDBRow >
              <MDBCol size="4" />
              <MDBCol lg="12" md="4">
                <div className="d-flex justify-content-center ">
                  <button onClick={()=>this.login()} color="orange"className="btnFormat" id="signUpButton">Log In</button>
                </div>
              </MDBCol>
              <MDBCol size="4" />
              </MDBRow> 

              <MDBRow id="processingLoader">
              <MDBCol size="4" />
              <MDBCol lg="12" md="4">
                <div className="d-flex justify-content-center">
                    <Loader className="mt-5"
                    type="Puff"
                    color="#3A499A"
                    height={50}
                    width={50}
                    margin-left={50}
                  />                  
                </div>
                <div className="d-flex justify-content-center">Processing</div>
              </MDBCol>
              <MDBCol size="4" />
            </MDBRow>  

          </MDBContainer>
        </header>
      </div>     
       
    
    );
    
  }
}



export default FormSubmissionTable;
