import React, { useState } from 'react';
import {
  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBContainer, MDBRow, MDBModal, MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, 
   MDBInput, MDBForm, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardText, MDBAlert,MDBDatePickerV5 
} from 'mdbreact';
import Select from 'react-select';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader-spinner';
import {ApplicationType} from '../Components/SelectData';
// import { postDatatoDb } from '../../components/JSfunctions/Utils';





class FormSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      name:"",
      address:"",
      eircode:"",
      city:"",
      email:"",
      contact:"",
      surname:"",
      postCode:"",
      firstname:"",
      addressOne:"",
      addressTwo:"",
      guarantorsName:"",
      applicationType:"",
      additionalDetails:"",
      guarantorsAddress:"",
      guarantorsPermitNo:"",
      contactNumberPrimary:"",
      contactNumberSecondary:"",
      fisheryOffence:"",
      permitRefused:"",
      permitRefusedSelectedValue:"",
      decleration:false,
      photoFileName:"",
      authorisation:false,
      applicationTypeSelected:"",
      fileName:'',
      titleText:"",
      dateValue:"",
      hasFisheryOffence:false,
      hasFisheryOffenceTrue:false,
      hasFisheryOffenceFalse:false,
      hasHadPermitRefused:true,
      hasHadPermitRefusedTrue:false,
      hasHadPermitRefusedFalse:false,
      showProfileImage:false,
      productselected:"",
      addednotes:"",
      contactError:false,
      radio: 0,
      location:"",
      dynamicErrorColor:"",
      dynamicError:"",
      dpaMessage:"",
      showDpaMessage:false,
      showDynamicError:false,
      selectedFile:null,
      filename:"",
      PhotoText:"",
      showSendBtn:true,
      showUploadFile:true,
      showApproveBtns:false,
      ApplicationNumber:"",
      disabledFields:false,
      dobField:"",
      role:"User",
      showAdminAppType:false,
      showNormalAppType:true
    };
    this.onClick = this.onClick.bind(this);
    this.datatest = "";
    this.DeclerationChecked = this.DeclerationChecked.bind(this);
    
  }
  

  DeclerationChecked(){
    this.setState({
      authorisation: !this.state.authorisation
    });
    //console.log(this.state.energyChecklist);

    if(this.state.authorisation === "Yes")
    {
      this.setState({        decleration: "true"      });      
    }
    else{
      this.setState({        decleration: "false"      });      
    }
    }


  fisheriesOffenceHandler  = cT => () => {

    this.setState({
      hasFisheryOffence: cT,
      
    });
    console.log(cT)

    if(cT === 'true')
    {
      this.setState({
        hasFisheryOffenceTrue: true,
        hasFisheryOffenceFalse: false
      });
    }
    else if(cT === 'false'){
      this.setState({
        hasFisheryOffenceTrue: false,
        hasFisheryOffenceFalse: true        
      });
    }
    else{

      this.setState({
        hasFisheryOffenceTrue: false,
        hasFisheryOffenceFalse: false        
      });
    }

  };


  revokedPermit  = rP => () => {

    this.setState({
      hasHadPermitRefused: rP
    });
    console.log(rP)

    if(rP === "permitRefused_true")
    {
      this.setState({
        permitRefusedSelectedValue: "true",
        hasHadPermitRefusedTrue:true,
        hasHadPermitRefusedFalse:false,
      });
    }
    else{
      this.setState({
        permitRefusedSelectedValue: "false",
        hasHadPermitRefusedTrue:false,
        hasHadPermitRefusedFalse:true,
      });
    }

  };
  supplyRegionCheck  = cT => () => {
    this.setState({
      supplyRegion: cT
    });
  };




  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

changeHandler = event => {
  this.setState({
    [event.target.name]: event.target.value
  });
  console.log(event.target.value);
};

changeHandlerApplicationType = event => {
  this.setState({
    applicationTypeSelected: event.target.value
  });
  console.log(event.target.value);
};


handleFileChange = (event) => {
  this.setState({
    selectedFile: event.target.files[0],
    filename: event.target.files[0].name,
  });
};



  async componentDidMount() {
    // const [formData, setFormData] = useState(FormData);
    $('#processingLoader').hide();
    $('#signUpBtnBilling').show();
    $('#newLoader').hide();
    $('#dynamicError').hide();

    this.setState({userId:this.props.userId});
    var applicationId = this.props.userId;
    console.log("App ID");
    console.log(applicationId)

    if(applicationId !== undefined)
    {  

      await axios.get(process.env.REACT_APP_API_ACTIVE_URL + "UserApplications/" + applicationId)
      .then(response => {
        console.log("Data");
        console.log(response.data);
        console.log(response.data.anglerNo);
        console.log(response.data[0].dateOfBirth);
        this.setState({ 
          dobField:response.data[0].dateOfBirth,
          titleText:"Application Details",
          selectedFile: response.data[0].selectedFile,
          ApplicationNumber:response.data[0].id,
          city: response.data[0].city,
          email: response.data[0].email,
          surname: response.data[0].surname,
          postCode: response.data[0].postCode,
          firstName: response.data[0].firstName,
          addressOne: response.data[0].addressOne,
          addressTwo: response.data[0].addressTwo,
          fileName: response.data[0].photoFileName,
          guarantorsName: response.data[0].guarantorsName,
          applicationTypeSelected: response.data[0].applicationType,
          additionalDetails: response.data[0].additionalDetails,
          guarantorsAddress: response.data[0].guarantorsAddress,
          guarantorsPermitNo: response.data[0].guarantorsPermitNo,
          contactNumberPrimary: response.data[0].contactNumberPrimary,
          contactNumberSecondary: response.data[0].contactNumberSecondary,
          hasFisheryOffence: response.data[0].hasFisheryOffence,
          permitRefusedSelectedValue: response.data[0].permitRefusedSelectedValue,
          decleration: response.data[0].decleration,
          name: response.data[0].name,
          dateofbirth: response.data[0].dateOfBirth,
          photoId:response.data[0].photoId,
          showProfileImage:true,
          PhotoText:"",
          showSendBtn:false,
          showUploadFile:false,
          showApproveBtns:true,
          disabledFields:true,
          showAdminAppType:true,
          showNormalAppType:false
        });
    
        console.log("DOB");
        console.log(this.state.dateOfBirth);



        if(response.data[0].fisheryOffence === true)
        {
          this.setState({
            hasFisheryOffenceTrue: true,
            hasFisheryOffenceFalse: false
          });
        }
        else if(response.data[0].fisheryOffence === false){
          this.setState({
            hasFisheryOffenceTrue: false,
            hasFisheryOffenceFalse: true        
          });
        }
        else{
    
          this.setState({
            hasFisheryOffenceTrue: false,
            hasFisheryOffenceFalse: false        
          });
        }


        if(response.data[0].permitRefused === false)
        {
          this.setState({
            permitRefusedSelectedValue: "true",
            hasHadPermitRefusedTrue:false,
            hasHadPermitRefusedFalse:true,
          });
        }
        else if(response.data[0].permitRefused === true){
          this.setState({
            permitRefusedSelectedValue: "false",
            hasHadPermitRefusedTrue:true,
            hasHadPermitRefusedFalse:false,
          });
        }
        else{
          this.setState({
            permitRefusedSelectedValue: "false",
            hasHadPermitRefusedTrue:false,
            hasHadPermitRefusedFalse:false,
          });
        }





        

        })
        .catch(err => {
          console.log("err");
          console.log(err);
      })




    }
    else{
      this.setState({
        titleText:"New Angler Application",
        disabledFields:false,
      });
    }


  




  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }



  submitHandler = event => {
    event.preventDefault();
    $('#processingLoader').show();
    $('#signUpBtn').hide();
    var addDetails = "N/A";

    if(this.state.additionalDetails !== "")
    {
      addDetails = this.state.additionalDetails;
    }

    var valid = "";
    valid = event.target.reportValidity();


    if(valid === true)
    {
      if(this.state.selectedFile === null)
      {
        alert("Please make sure to include your photo Id");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }

      if(this.state.hasFisheryOffence === undefined)
      {
        alert("Please select weather you have ever had a previous fishery offence");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }

      if(this.state.permitRefusedSelectedValue === "")
      {
        alert("Please select weather you have ever had a permit refused in the past");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }

      
      if(this.state.decleration === "")
      {
        alert("Please confirm you will abide by the company and fishery rules");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }
  
      const formDataNew = new FormData();
      formDataNew.append('file', this.state.selectedFile);
     
  
        var url =   process.env.REACT_APP_API_ACTIVE_URL + 'UserApplications';  
  
  
        let headersUsed = new Headers();
        headersUsed.append('Content-Type', 'application/json');
        headersUsed.append('Accept', 'application/json');
      
        
        headersUsed.append('Access-Control-Allow-Credentials', 'true');
      
        headersUsed.append('GET', 'POST', 'OPTIONS');
  
        //need to convert the byte data to be a value that can be passed back to the api


  
          const formdata = new FormData();
          formdata.append('PhotoId', this.state.selectedFile);
          formdata.append("City", this.state.city);
          formdata.append("Email", this.state.email);
          formdata.append("Surname", this.state.surname);
          formdata.append("PostCode", this.state.postCode);
          formdata.append("FirstName", this.state.firstName);
          formdata.append("AddressOne", this.state.addressOne);
          formdata.append("AddressTwo", this.state.addressTwo);
          formdata.append("DisplayDate", "Pass");
          formdata.append("GuarantorsName", this.state.guarantorsName);
          formdata.append("ApplicationType", this.state.applicationTypeSelected);
          formdata.append("AdditionalDetails", addDetails);
          formdata.append("GuarantorsAddress", this.state.guarantorsAddress);
          formdata.append("GuarantorsPermitNo", this.state.guarantorsPermitNo);
          formdata.append("ContactNumberPrimary", this.state.contactNumberPrimary);
          formdata.append("ContactNumberSecondary", this.state.contactNumberSecondary);
          formdata.append("FisheryOffence", this.state.hasFisheryOffence);
          formdata.append("PermitRefused", this.state.permitRefusedSelectedValue);
          formdata.append("Decleration", this.state.decleration);
          formdata.append("PhotoFileName", this.state.selectedFile.name);
          formdata.append("DateOfBirth", this.state.dateofbirth);
         
  
          axios.post(url, formdata).then((response) => {
            // handle success
            alert("Application Submitted Succesfully ");
            $('#processingLoader').hide();
            $('#signUpBtn').show();
            setTimeout(
              function() {
                  window.location.reload();
              }
              .bind(this),
              1000
            );
            
          }).catch((error) => {
            // handle error
            console.log(error);
            alert(error);
          });
        }
        else{
          $('#processingLoader').hide();
          $('#signUpBtn').show();
        }
        


  };


  aprroveUser(){

    

    var sixDigits = Math.floor(100000 + Math.random() * 900000);
    var refNo = 'ANG_' + sixDigits
    
      var url =   process.env.REACT_APP_API_ACTIVE_URL + 'approveuser';  
      console.log("DOB");
      console.log(this.state.dobField);

      let headersUsed = new Headers();
      headersUsed.append('Content-Type', 'application/json');
      headersUsed.append('Accept', 'application/json');
    
      
      headersUsed.append('Access-Control-Allow-Credentials', 'true');
    
      headersUsed.append('GET', 'POST', 'OPTIONS');


        const formdata = new FormData();
        
        //formdata.append('AnglerNo', this.state.anglerNo);
        formdata.append("Email", this.state.email);
        formdata.append("FirstName", this.state.firstName);
        formdata.append("Surname", this.state.surname);
        formdata.append("DateOfBirth", this.state.dobField);
        formdata.append("signupType", this.state.applicationTypeSelected);
        formdata.append("Address", this.state.addressOne + " " + this.state.addressTwo);
        formdata.append("Street", this.state.addressTwo);
        formdata.append("Country", "");
        formdata.append("PostCode", this.state.postCode);
        formdata.append("PrimaryContactNo", this.state.contactNumberPrimary);
        formdata.append("SecondaryContactNo", this.state.contactNumberSecondary);
        formdata.append("City", this.state.city);
        formdata.append("MemberStatus", "Active");
        formdata.append("Username", this.state.email);
        formdata.append("Password", "");
        formdata.append("ApplicationNumber", this.state.ApplicationNumber);
        formdata.append("Role", this.state.role);
        formdata.append("PhotoFileName", this.state.fileName);
        formdata.append("Decleration", this.state.decleration);



        axios.post(url, formdata).then((response) => {
          // handle success
          alert("User Added Succesfully")

          // this.state.formDataTest = e.target.files[0];
          setTimeout(
            function() {
                window.location.reload();
            }
            .bind(this),
            1500
          );
        }).catch((error) => {
          // handle error
          console.log(error);
          alert(error);
        });
 


  }

  handleChangeRole = event => {
    console.log(event.target.value);
    this.setState({role:event.target.value})
    



  };

  
  async decline(){

    await axios.get(process.env.REACT_APP_API_ACTIVE_URL + "UserApplications/" + this.state.ApplicationNumber + "/true")
    .then(response => {
      console.log("App Declined Succesfully");
      console.log(response);
      alert("Application Declined Succesfully")

        // this.state.formDataTest = e.target.files[0];
        setTimeout(
          function() {
              window.location.reload();
          }
          .bind(this),
          1500
        );

      })
      .catch(err => {
        console.log("err");
        console.log(err);
    })

    

  }



  async uploadFile(e) {

    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("formFile", file)
    console.log("Data Below 3");   
    console.log(formData);     
         
    
    // this.state.formDataTest = e.target.files[0];
    setTimeout(
      function() {
        console.log(formData.get('formFile'));
        this.setState({formDataTest:formData.get('formFile')})
        console.log(formData.get('formFile'));
      }
      .bind(this),
      2000
    );
      
  }

  

  render() {
    const { applicationTypeSelected } = this.state;
    const { titleText } = this.state;
    const {PhotoText} = this.state; 
    const {disabledFields} = this.state; 

    const options = [
      {value: '', text: '--Application Type--'},
      {value: 'Junior_1', text: 'Junior 1 (At 1st April in year is aged below 13)'},
      {value: 'Junior_2', text: 'Junior 2 (At 1st April in year is aged 13 or above but below 18)'},
      {value: 'Adult ', text: 'Adult (Aged 18 or above but below 65 at 1st April in year of season)'},
      {value: 'Pensioner', text: 'Pensioner (aged 65 or above but below 80 at 1st April in year of season)'},
      {value: 'Octogenarian ', text: 'Octogenarian (Aged 80 or more at 1sr April in year of season)'},
      // {value: 'Riparian ', text: 'Riparian'},
      // {value: 'Life_Member  ', text: 'Life Member'}
    ]
    const optionsAdmin = [
      {value: '', text: '--Application Type--'},
      {value: 'Junior_1', text: 'Junior 1 (At 1st April in year is aged below 13)'},
      {value: 'Junior_2', text: 'Junior 2 (At 1st April in year is aged 13 or above but below 18)'},
      {value: 'Adult ', text: 'Adult (Aged 18 or above but below 65 at 1st April in year of season)'},
      {value: 'Pensioner', text: 'Pensioner (aged 65 or above but below 80 at 1st April in year of season)'},
      {value: 'Octogenarian ', text: 'Octogenarian (Aged 80 or more at 1sr April in year of season)'},
      {value: 'Riparian ', text: 'Riparian'},
      {value: 'Life_Member  ', text: 'Life Member'}
    ]


    const roleoptions = [
      {value: '', text: '--Order Type--'},
      {value: 'User', text: 'User'},
      {value: 'Admin', text: 'Admin'},
    ]
    

    return (      
          <div > 
        <header>
  
          <MDBContainer id="signUp2" fluid className="mt-5 mb-5">

          <form
           id="solarform"
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
          >
          {
          this.state.showProfileImage?
          <MDBRow>
              <MDBCol size="12" className="text-center">
              <img className="profilePicImageSzViewuser text-center" src={"data:image/png;base64," + this.state.photoId} alt="Image" />
              </MDBCol>
            </MDBRow>
          :null
          }

            <MDBRow>
              <MDBCol size="12">
                <p className="display-7 text-center text-uppercase">{titleText}</p>
                <hr className="divider" />
              </MDBCol>
            </MDBRow>

            {/*Dynamic error*/}
            {
            this.state.showDynamicError?
            <div>
                 <MDBRow>
                   <MDBCol size="3" />
                   <MDBCol lg="6" md="12">
                     <MDBAlert color={this.state.dynamicErrorColor} dismiss>
                       {this.state.dynamicError}
                       </MDBAlert>
                     </MDBCol>
                     <MDBCol size="3" />
                   </MDBRow>
             </div>
            :null            
            } 



             <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12" />
                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.firstName}
                    onChange={this.changeHandler}
                    type="text"
                    id="firstName"
                    className="form-control"
                    name="firstName"
                    maxLength="30"
                    pattern="^[a-zA-Z]*$"
                    placeholder="First Name"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>

                <MDBCol lg="4" md="12"  className="mb-3" >
                <div className="dark-grey-text">
                <input
                    value={this.state.surname}
                    onChange={this.changeHandler}
                    type="text"
                    id="surname"
                    className="form-control"
                    name="surname"
                    maxLength="30"
                    pattern="^[a-zA-Z]*$"
                    placeholder="surname"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>

              </MDBRow>



              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12"/>
                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                <textarea
                    value={this.state.addressOne}
                    onChange={this.changeHandler}
                    type="textarea"
                    id="addressOne"
                    className="form-control"
                    name="addressOne"
                    maxLength="50"
                    //pattern="^[^'<>{};]*$"
                    placeholder="Address One"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>

                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                <textarea
                    value={this.state.addressTwo}
                    onChange={this.changeHandler}
                    type="textarea"
                    id="addressTwo"
                    className="form-control"
                    name="addressTwo"
                    //pattern="^[^'<>{};]*$"
                    maxLength="50"
                    placeholder="Address Two"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>



              </MDBRow>

 


              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12"/>
                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.city}
                    onChange={this.changeHandler}
                    type="text"
                    id="city"
                    className="form-control"
                    name="city"
                    maxLength="30"
                    //pattern="^[^'<>{};]*$"
                    placeholder="city"
                    // required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>

                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.postCode}
                    onChange={this.changeHandler}
                    type="text"
                    id="postCode"
                    className="form-control"
                    name="postCode"
                    maxLength="10"
                    //pattern="^[^'<>{};]*$"
                    placeholder="Post Code"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>
              </MDBRow>



              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12"/>
                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                  <input
                    value={this.state.email}
                    onChange={this.changeHandler}
                    type="text"
                    id="email"
                    className="form-control"
                    name="email"
                    maxLength="50"
                    //pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    placeholder="Email Address"
                    // required
                    disabled={disabledFields}
                  />

                </div>
                </MDBCol>

                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                  <input
                    value={this.state.contactNumberPrimary}
                    onChange={this.changeHandler}
                    type="text"
                    id="contactNumberPrimary"
                    className="form-control"
                    name="contactNumberPrimary"
                    maxLength="15"
                    pattern="^[0-9_ ]*$"
                    placeholder="Primary Contact Number"      
                    disabled={disabledFields}              
                  />
                </div>
                </MDBCol>

              </MDBRow>



              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12"/>
                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                  <input
                    value={this.state.contactNumberSecondary}
                    onChange={this.changeHandler}
                    type="text"
                    id="contactNumberSecondary"
                    className="form-control"
                    name="contactNumberSecondary"
                    maxLength="15"
                    pattern="^[0-9_ ]*$"
                    placeholder="Secondary Contact Number"
                    // required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>


                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                  <input
                    value={this.state.dateofbirth}
                    onChange={this.changeHandler}
                    type="date"
                    id="dateofbirth"
                    className="form-control"
                    name="dateofbirth"
                    maxLength="15"
                    pattern="^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}"
                    placeholder="D.O.B"
                    required
                    disabled={disabledFields}
                  />


                </div>
                </MDBCol>

              </MDBRow>


              {
                this.state.showAdminAppType?
                  <MDBRow>
                  {/*Form Left Column*/}
                  <MDBCol lg="2" md="12"/>
                  <MDBCol lg="8" md="12">
                    <select  required id="orderTypeSelect" className="form-select-box titleSelect selectBx" disabled={disabledFields} value={applicationTypeSelected} onChange={this.changeHandlerApplicationType}>
                    {optionsAdmin.map(option => (
                      <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                    </MDBCol>
                  </MDBRow>                
                :null
              }

              
              {
                this.state.showNormalAppType?
                  <MDBRow>
                  {/*Form Left Column*/}
                  <MDBCol lg="2" md="12"/>
                  <MDBCol lg="8" md="12">
                    <select  required id="orderTypeSelect" className="form-select-box titleSelect selectBx" disabled={disabledFields} value={applicationTypeSelected} onChange={this.changeHandlerApplicationType}>
                    {options.map(option => (
                      <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                    </MDBCol>
                  </MDBRow>                
                :null
              }









              <MDBRow className="pb-4 mt-5">
                  <MDBCol size="2" />
                  <MDBCol lg="8" md="12" >
                    <p className="display-9 orange-underline text-uppercase">Have you ever been cONVICTED OF FISHERIES OFFENCE?</p>
                  </MDBCol>
                  <MDBCol size="2" />
                </MDBRow>


                <MDBRow className="mb-5">
                  <MDBCol size="2" />
                  <MDBCol lg="4" md="12">
                    <div className="inputGroup">
                      <input onClick={this.fisheriesOffenceHandler('true')} disabled={disabledFields} checked={this.state.hasFisheryOffenceTrue} id="true" name="true" type="radio" />
                      <label for="true" className="pl-4">
                        <i className="fas fa-thumbs-up iconcolor fa-lg" />
                        &nbsp;&nbsp; Yes
                      </label>
                    </div>
                  </MDBCol>
                  <MDBCol lg="4" md="12">
                    <div className="inputGroup">
                      <input onClick={this.fisheriesOffenceHandler('false')} disabled={disabledFields} checked={this.state.hasFisheryOffenceFalse} id="false" name="false" type="radio" />
                      <label for="false" className="pl-4">
                        <i className="fas fa-thumbs-down iconcolor fa-lg" />
                        &nbsp;&nbsp; No
                      </label>
                    </div>
                  </MDBCol>
                  <MDBCol lg="2" md="12" />
                </MDBRow>






                <MDBRow className="pb-4 mt-5">
                  <MDBCol size="2" />
                  <MDBCol lg="8" md="12" >
                    <p className="display-9 orange-underline text-uppercase">Have you ever had A FISHING PERMIT REFUSED/REVOKED?</p>
                  </MDBCol>
                  <MDBCol size="2" />
                </MDBRow>

                <MDBRow  >
                  <MDBCol size="2" />
                  <MDBCol lg="4" md="12">
                    <div className="inputGroup">
                      <input onClick={this.revokedPermit('permitRefused_true')} disabled={disabledFields} checked={this.state.hasHadPermitRefusedTrue} id="permitRefused_true" name="permitRefused_true" type="radio" />
                      <label for="permitRefused_true" className="pl-4">
                        <i className="fas fa-thumbs-up iconcolorsolar fa-lg" />
                        &nbsp;&nbsp; YES
                      </label>
                    </div>
                  </MDBCol>
                  <MDBCol lg="4" md="12">
                    <div className="inputGroup">
                      <input onClick={this.revokedPermit('permitRefused_false')} disabled={disabledFields} checked={this.state.hasHadPermitRefusedFalse} id="permitRefused_false" name="permitRefused_false" type="radio" />
                      <label for="permitRefused_false" className="pl-4">
                        <i className="fas fa-thumbs-down iconcolorsolar fa-lg" />
                        &nbsp;&nbsp; No
                      </label>
                    </div>
                  </MDBCol>
                  <MDBCol lg="2" md="12" />
                </MDBRow>


                <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12"/>
                <MDBCol lg="8" md="12" className="pb-4 mt-5">
                <div className="dark-grey-text">
                  <textarea
                    value={this.state.additionalDetails}
                    onChange={this.changeHandler}
                    type="texarea"
                    id="additionalDetails"
                    className="form-control"
                    name="additionalDetails"
                    maxLength="15"
                    //pattern="^[^'<>{};]*$"
                    placeholder="If Yes to either of the above questions please give details"
                    disabled={disabledFields}
                  />


                </div>
                </MDBCol>
              </MDBRow>

              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12"/>
                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.guarantorsName}
                    onChange={this.changeHandler}
                    type="text"
                    id="guarantorsName"
                    className="form-control"
                    name="guarantorsName"
                    maxLength="40"
                    pattern="^[a-zA-Z0-9\s]+$"
                    placeholder="guarantors name"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>

                <MDBCol lg="4" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.guarantorsPermitNo}
                    onChange={this.changeHandler}
                    type="text"
                    id="guarantorsPermitNo"
                    className="form-control"
                    name="guarantorsPermitNo"
                    maxLength="30"
                    //pattern="^[^'<>{};]*$"
                    placeholder="guarantor permit number"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>
              </MDBRow>


              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="2" md="12"/>
              <MDBCol lg="8" md="12" className="mb-3">
                <div className="dark-grey-text">
                <textarea
                    value={this.state.guarantorsAddress}
                    onChange={this.changeHandler}
                    type="textarea"
                    id="guarantorsAddress"
                    className="form-control"
                    name="guarantorsAddress"
                    maxLength="20"
                    //pattern="^[^'<>{};]*$"
                    placeholder="guarantors address"
                    required
                    disabled={disabledFields}
                  />
                </div>
                </MDBCol>
              </MDBRow>





          {/*Authorization*/}
          <MDBRow className="optionCssJoinForm mt-3">
            <MDBCol size="2" />
            <MDBCol lg="8" md="12">            
              <div className="inputGroup resize-input-95 float-right" id="decleration">
                <input
                  checked={ this.state.decleration }
                  onChange={ this.DeclerationChecked }
                  id="declerationCheckbox"
                  name="declerationCheckbox"
                  type="checkbox"
                  disabled={disabledFields}
                />
              <label id="declerationCheckboxText" for="declerationCheckbox">
              I DECLARE THAT IF GRANTED A PERMIT THAT I AGREE TO ABIDE BY COMPANY AND FISHERY RULES
              </label>
              </div>
            </MDBCol>
            <MDBCol size="3" />
          </MDBRow>

          


          {/*Authorization*/}
          <MDBRow className="optionCssJoinForm mt-3 text-center mt-5">
            <MDBCol lg="12" md="12">            
            <div className="d-flex justify-content-center">
              <p className="photoupldtext text-center text-uppercase">{PhotoText}</p>
              </div>
            </MDBCol>
            <MDBCol size="3" />
          </MDBRow>

              {
                this.state.showUploadFile?
                <MDBRow id="signUpBtnBilling" className="mb-5">
                  {/*Form Left Column*/}
                  <MDBCol lg="3" md="12"/>
                  <MDBCol lg="6" md="4">
                    <div className="d-flex justify-content-center mt-3">
                    {/* <input className="photoupldFile text-center text-uppercase"  id="uploadCaptureInputFile" type="file" onChange={this.uploadFile}/> */}
                    <input type="file" name="file" onChange={this.handleFileChange} />
                    </div>
                  </MDBCol>
                  </MDBRow>  
                :null          
               }  


               {
                this.state.showSendBtn?
                  <MDBRow >
                  {/*Form Left Column*/}
                  <MDBCol lg="2" md="12"/>
                  <MDBCol lg="8" md="4">
                    <div id="signUpBtn" className="d-flex justify-content-center mt-3 mb-5">
                    <button type="submit" className="btnFormat" id="submitButton">Send</button>            
                      
                    </div>
                  </MDBCol>
                  </MDBRow> 
                :null          
               }  
          

 

              {
                this.state.showDpaMessage?
                <MDBRow id="signUpBtnBilling">
                {/*Form Left Column*/}
                <MDBCol lg="2" md="12"/>
                <MDBCol lg="6" md="4">
                  <div className="d-flex justify-content-center mt-3">
                    <p >{this.state.dpaMessage}</p>
                    
                  </div>
                </MDBCol>
                </MDBRow>  
                :null
              }

          </form>

          {
                this.state.showApproveBtns?
           <div>
           <MDBRow >
           <MDBCol lg="3" md="12" />
           <MDBCol lg="5" md="12">
              <div className="dark-grey-text">
                <label className="labelCss" for="role">Role</label><br></br>
                <select disabled={true} required id="role" className="form-select-box titleSelect selectBx orderTypeSelect" value={this.state.role} onChange={this.handleChangeRole}>
                {roleoptions.map(option => (
                  <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
                </div>
                </MDBCol>

                </MDBRow>
          
                  <MDBRow >
                    {/*Form Left Column*/}
                    <MDBCol lg="2" md="12"/>
                    <MDBCol lg="4" md="4">
                      <div  className="d-flex justify-content-center mt-3 mb-5">
                      <button  onClick={()=>this.aprroveUser()} className="btnFormat" id="aprrove">Approve</button>             
                        
                      </div>
                    </MDBCol>
                    <MDBCol lg="4" md="4">
                      <div id="signUpBtn" className="d-flex justify-content-center mt-3 mb-5">
                      <button onClick={()=>this.decline()} className="btnFormat" id="decline">Decline</button>             
                        
                      </div>
                    </MDBCol>
                    </MDBRow>  
                    </div>
                :null
               }   
        

              <MDBRow id="processingLoader">
              <MDBCol size="4" />
              <MDBCol lg="12" md="4">
                <div className="d-flex justify-content-center">
                    <Loader className="mt-5"
                    type="Puff"
                    color="#3A499A"
                    height={50}
                    width={50}
                    margin-left={50}
                  />                  
                </div>
                <div className="d-flex justify-content-center">Processing</div>
              </MDBCol>
              <MDBCol size="4" />
            </MDBRow>  
            
              


          </MDBContainer>
        </header>
      </div>     
       
    
    );
    
  }
}

export default FormSubmission;
