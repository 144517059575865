import React from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBContainer, MDBRow, MDBModal, MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, 
   MDBInput, MDBForm, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardText, MDBAlert,MDBDatePickerV5 
} from 'mdbreact';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader-spinner';
import UserMyDetails from '../Components/UserMyDetails';
import Form from '../Components/FormSubmission';
import AppTable from '../Components/FormSubmissionTable';
import UserTable from '../Components/UsersTable';
import Login from './Login'
import Select from 'react-select';
import ViewUser from '../Components/UserFormUpdateDetails';
// import { postDatatoDb } from '../../components/JSfunctions/Utils';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      userDetails:[],
      userId:"",
      showLoggedIn:false,
      showLoggedOut:false,
      showAdmin:false,
      showUser:false,
    };
    this.onClick = this.onClick.bind(this);
  }

 
  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }
  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  toggleEditUser = test2 => () => {
    console.log("test2")
    console.log(test2)

    this.setState({
      modalManagerChange: !this.state.modalManagerChange,
      userId:test2
    });
  }

  async componentDidMount() {
    
    $('#processingLoader').hide();
    $('#signUpBtnBilling').show();
    $('#newLoader').hide();
    $('#dynamicError').hide();

    var x = localStorage.getItem("currentUser");
    var obj = JSON.parse(x);

    var userToken = localStorage.getItem("userRole");
    var userRole = "";


    console.log("obj User Home");
    console.log(obj);
    console.log("Role Is : " + userToken);

    if(userToken === "Admin")
    {
      this.setState({ 
        showAdmin:true,
        showUser:false
      });
    }
    else{
      this.setState({ 
        showAdmin:false,
        showUser:true
      });
    }

    if(obj !== null)
    {
      //Set Details
      this.setState({ 
        userDetails:obj,
        showLoggedIn:true,
        showLoggedOut:false
      });

      console.log("Admin role")
      console.log(obj[0].role)

      
    }
    else{
        //Set Details
        this.setState({ 
          showLoggedIn:false,
          showLoggedOut:true
        });
     // window.location.href = "/Login";
    }




  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }


  redirect(url){

    window.location.href = url;
  }


  render() {
    const { userDetails } = this.state;
    return (      
          <div className="mb-5  pb-5"> 
        <header>
                  
        <MDBModal isOpen={this.state.modalManagerChange} toggle={this.toggleEditUser("Close")} size="lg">
                <MDBRow>
                  <MDBCol size="12" className="text-right ">
                    <i class="exPointer fas fa-times fa-2x pr-5 pt-3 be-blue-text ml-5" onClick={this.toggleEditUser("Close")} style={{ cursor: "pointer" }} />
                  </MDBCol>
                </MDBRow>           
              <ViewUser userId={userDetails.id}/>
          </MDBModal>

          {
            this.state.showLoggedIn?
            <MDBContainer fluid className="mt-5 mb-5">
              <MDBRow className="view sticky-navbar-shadow">
                  <MDBCol size="12" className="full-img text-center">
                  <p className="Heading-One display-7 text-center text-uppercase">Welcome {userDetails.firstName}</p>
                  <hr className="divider" />
                  <p className="display-7 text-center text-uppercase">Please find your angler account details below</p>
                  </MDBCol>
                </MDBRow>

                {/* <UserMyDetails/> */}



          {
            this.state.showAdmin?
              <div>
                  <p className=" text-center text-uppercase mt-5">Looks like you are an admin user</p>
                            <hr className="divider" />

                          <ul className="horizontal-list mt-3 text-center">
                          <li >
                                <MDBCol lg="12" md="12" >
                                  <div class="card">
                                    <img id="Logo" className="homepagetileimagecss"   src={process.env.PUBLIC_URL + '/images/fishing.jpg'} />
                                    <div class="description">
                                      {/* <p>Description goes here.</p> */}
                                      <button className="btnFormat" onClick={()=>this.redirect("/ViewUsers")}  color="orange" size="md">
                                      <MDBIcon far icon="clone" className="left" /> View Users </button>
                                    </div>
                                  </div>
                                </MDBCol>   
                              </li>
                              <li >
                                      <MDBCol lg="12" md="12" >
                                        <div class="card">
                                        <img id="Logo" className="homepagetileimagecss"   src={process.env.PUBLIC_URL + '/images/laptopapply.jpg'} />
                                          <div class="description">
                                            {/* <p>Description goes here.</p> */}
                                            <button className="btnFormat" onClick={()=>this.redirect("/ViewApplications")}  color="orange" size="md">
                                            <MDBIcon far icon="clone" className="left" /> View Applications </button>
                                          </div>
                                        </div>
                                      </MDBCol> 
                              </li>
                              
                          </ul>
              </div>
            :null

          }
          {
            this.state.showUser?
              <div>
              <ul className="horizontal-list mt-3 text-center">
                <li className="liWidthProfileUserCss">
                      <MDBCol lg="12" md="12" >
                        <div class="card cardProfileImageHomeCss">
                        <img id="Logo" className="homepagetileimagecss"   src={process.env.PUBLIC_URL + '/images/fishing.jpg'} />

                          <div className="description">
                          <table border="1" className="tableMyAcc mb-3">
                                <tbody className="tableMyAccBody">
                                  <tr className="tableMyAccTr">
                                    <td className="tableMyAccTrd">Angler N.O</td>
                                    <td className="tableMyAccTrd">{userDetails.anglerNo}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Name</td>
                                    <td className="tableMyAccTrd">{userDetails.firstName + " " + userDetails.surname }  </td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Address</td>
                                    <td className="tableMyAccTrd">{userDetails.address}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">City</td>
                                    <td className="tableMyAccTrd">{userDetails.city}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Country</td>
                                    <td className="tableMyAccTrd">{userDetails.country}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">PostCode</td>
                                    <td className="tableMyAccTrd">{userDetails.postCode}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Date Of Birth</td>
                                    <td className="tableMyAccTrd">{userDetails.dateOfBirth}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Member Status</td>
                                    <td className="tableMyAccTrd">{userDetails.memberStatus}</td>
                                  </tr>

                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Contact N.O</td>
                                    <td className="tableMyAccTrd">{userDetails.primaryContactNo}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Account Type</td>
                                    <td className="tableMyAccTrd">{userDetails.signupType}</td>
                                  </tr>
                                  <tr className="tableMyAccTr">
                                  <td className="tableMyAccTrd">Role</td>
                                    <td className="tableMyAccTrd">{userDetails.role}</td>
                                  </tr>
                                </tbody>
                              </table>
                            <button className="btnFormat"   onClick={this.toggleEditUser(userDetails.id)} color="orange" size="md">
                            <MDBIcon far icon="clone" className="left" /> Edit</button>
                          </div>
                        </div>
                      </MDBCol>   
                    </li>
                    {/* <li >
                            <MDBCol lg="12" md="12" >
                              <div class="card">
                                <img src="https://budgetenergy.co.uk/homepage/meter-reading.jpg" alt="Image"/>
                                <div class="description">
                                  <button className="btnFormat" onClick={()=>this.redirect("/ViewApplications")}  color="orange" size="md">
                                  <MDBIcon far icon="clone" className="left" /> View Applications </button>
                                </div>
                              </div>
                            </MDBCol> 
                    </li> */}
                    
                </ul>
              </div>
            :null

          }  






        

   
  

              </MDBContainer>

            :null
          }
          {
            this.state.showLoggedOut?

            <Form/>
            :null
          }

        </header>
      </div>     
       
    
    );
    
  }
}

export default Home;
