import React from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBContainer, MDBRow, MDBModal, MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, 
   MDBInput, MDBForm, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardText, MDBAlert,MDBDatePickerV5 
} from 'mdbreact';
import Select from 'react-select';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader-spinner';
import {ApplicationType} from './SelectData';

// import { postDatatoDb } from '../../components/JSfunctions/Utils';

class DeleteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      name:"",
      address:"",
      eircode:"",
      email:"",
      contact:"",
      productselected:"",
      addednotes:"",
      contactError:false,
      radio: 0,
      location:"",
      dynamicErrorColor:"",
      dynamicError:"",
      dpaMessage:"",
      showDpaMessage:false,
      showDynamicError:false,
      showProductTableroi:false,
      showProductTableni:false,
      thirdPartyDataNi:[],
      thirdPartyDataRoi:[],
      dataPasssed:[],
      userId:"",
    };
    this.onClick = this.onClick.bind(this);
  }

 
  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }
  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };



  async componentDidMount() {
    
    $('#processingLoader').hide();
    $('#signUpBtnBilling').show();
    $('#newLoader').hide();
    $('#dynamicError').hide();

    this.setState({userId:this.props.userId});
    var test = this.props.userId;
    console.log(test);

    // const {data} = await axios.get('http://customappserver/api/paymentissues/' + this.state.passedId)
    // this.setState({tableData: data})

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }


  render() {

    const {userId} = this.state; 

    return (      
          <div > 
            <header>
             Delete User {userId}
            </header>
          </div>   
    );
    
  }
}

export default DeleteUser;
