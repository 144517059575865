import React from 'react';
class FixedNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      showFixedNav:true,
      showLogin:false,
      showLogout:false
    };
    this.onClick = this.onClick.bind(this);
  }


  componentDidMount() {  
    var user = localStorage.getItem("currentUser");
  
    if(user === "" || user === null){
      this.setState({
        showLogin:true,
        showLogout:false
      });
    }
    else{
      this.setState({
        showLogin:false,
        showLogout:true
      });
    }

    }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  MyAccount(){      window.open("https://myaccount.budgetenergy.co.uk/");    
  }
  logout(){
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserRole");
    localStorage.removeItem("currentUserToken");

    setTimeout(
      function() {
        window.location.href = "/Login";
      }
      .bind(this),
      2000
    );
  }



  render() {

    return (
      <div className="sticky-navbar-shadow">
<nav className="sticky-navbar-shadow">
<img id="Logo" className="rfaLogoSize"   src={process.env.PUBLIC_URL + '/images/rfa.jpg'} />
        <input type="checkbox" id="checkbox"/>
        <label for="checkbox" id="icon">
            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
        </label>
        <ul>
            <li><a href="/" >Home</a></li>
            {/* <li><a href="/Form">Form</a></li> */}
            <li><a href="/">MyAccount</a></li>
            {/* <li><a href="/WhatWeDo">What We Do</a></li>
            <li><a href="/Gallery">Gallery</a></li>
            <li><a href="/FishingTheFaughan">Fishing the faughan</a></li>        
            <li><a href="/Contact">Contact</a></li> */}
            {this.state.showLogin? <li><a href="/login">Login</a></li>:null}
            {this.state.showLogout? <li><a className="logoutbtn" onClick={()=>this.logout()}  >Logout</a></li>:null}
        </ul>
    </nav>
{/* <a href="https://youtu.be/bRzhXfbaXng" target="_blank" class="link">Watch on youtube <i class="fab fa-youtube"></i></a> */}
    </div>
    );
  }
}

export default FixedNav;