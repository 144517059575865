import React, { useState } from 'react';
import {
  MDBRow, MDBCol
} from 'mdbreact';

export default function App() {
  return (
    <div >
      <header >
          <MDBRow className="view sticky-navbar-shadow">
              <MDBCol size="12" className="full-img text-center">
              FishingTheFaughan
              </MDBCol>
            </MDBRow>


      </header>
    </div>
  );
}