import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesPage from './routes/RoutesPage.js';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import MenuBarUser from './Components/MenuBarUser';
import MenuBarAdmin from './Components/MenuBarAdmin';
import MenuBarStandard from './Components/MenuBarStandard';
import Footer from './Components/Footer';

function App() {

  var showAdminMenu = false;
  var showUserMenu = false;
  var showMenuBarStandard = false;

  var userToken = localStorage.getItem("currentUserToken");
  var userRole = "";

  if(userToken !== null){
    var base64Url = userToken.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    var newobj = JSON.parse(jsonPayload);

    for (let key in newobj) {
      if (key === "http://schemas.microsoft.com/ws/2008/06/identity/claims/role") {
        localStorage.setItem('currentUserRole', newobj[key]);
        userRole = newobj[key];
        localStorage.setItem('userRole', userRole);
      }
      if (key === "exp") {
        try {              
          const expirationTime = newobj[key];
          console.log(expirationTime);
          const now = Date.now() / 1000; // convert to seconds
          
          if (now > expirationTime) {
            console.log('Token has expired');
            localStorage.removeItem("currentUser");
            localStorage.removeItem("currentUserRole");
          } else {
           
          }
        } catch (error) {
          console.log('Invalid token');
        }

    }
    }
  }
  else{
    console.log('No Token');
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserRole");
    localStorage.removeItem("currentUserToken");
  }

  if(userRole === "Admin")
  {
  var showAdminMenu = true;
  var showUserMenu = false;
  var showMenuBarStandard = false;
  }
  else if(userRole == "User")
  {
    var showAdminMenu = false;
    var showUserMenu = true;
    var showMenuBarStandard = false;
  }
  else{
    var showAdminMenu = false;
    var showUserMenu = false;
    var showMenuBarStandard = true;
  }

  return (
    <Router>
    {
      showAdminMenu?
        <MenuBarAdmin className="mb-5"/>
      :null
    }
    {
      showUserMenu?
        <MenuBarUser className="mb-5"/>
      :null
    }
    {
      showMenuBarStandard?
        <MenuBarStandard className="mb-5"/>
      :null
    }
    
    <RoutesPage  />
    <Footer  />
    
  </Router>
  );
}

export default App;
