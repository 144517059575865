import React from "react";
import { Routes, Route } from "react-router-dom";

// Main Content (Top Nav Links)
import HomePage from '../pages/Home.js';
import ViewUsers from '../pages/ViewUsers.js';
import ViewApplications from '../pages/ViewApplications.js';
import UserHome from '../pages/Home.js';
import Form from '../pages/Form.js';
import FishingTheFaughan from '../pages/FishingTheFaughan.js';
import Login from '../pages/Login.js';

class RoutesPage extends React.Component {
  render() {
    return (
        <Routes>
          <Route path="/" element={<HomePage />}>
          </Route>
          <Route path="/ViewUsers" element={<ViewUsers />}>
          </Route>
          <Route path="/ViewApplications" element={<ViewApplications />}>
          </Route>
          <Route path="/UserHome" element={<UserHome />}>
          </Route>
          <Route path="/Form" element={<Form />}>
          </Route>
          <Route path="/FishingTheFaughan" element={<FishingTheFaughan />}>
          </Route>
          <Route path="/Login" element={<Login />}>
          </Route>
        </Routes>
    );
  }
}
export default RoutesPage;
