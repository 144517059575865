import React, { useState } from 'react';
import {
  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBContainer, MDBRow, MDBModal, MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, 
   MDBInput, MDBForm, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardText, MDBAlert,MDBDatePickerV5 
} from 'mdbreact';
import Select from 'react-select';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader-spinner';
import {ApplicationType} from '../Components/SelectData';
// import { postDatatoDb } from '../../components/JSfunctions/Utils';

class UserMyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      name:"",
      address:"",
      eircode:"",
      city:"",
      email:"",
      contact:"",
      surname:"",
      postCode:"",
      firstname:"",
      addressOne:"",
      addressTwo:"",
      guarantorsName:"",
      applicationType:"",
      additionalDetails:"",
      guarantorsAddress:"",
      guarantorsPermitNo:"",
      contactNumberPrimary:"",
      contactNumberSecondary:"",
      fisheryOffence:"",
      permitRefused:"",
      permitRefusedSelectedValue:"",
      decleration:"",
      photoFileName:"",
      authorisation:false,
      applicationTypeSelected:"",






      productselected:"",
      addednotes:"",
      contactError:false,
      radio: 0,
      location:"",
      dynamicErrorColor:"",
      dynamicError:"",
      dpaMessage:"",
      showDpaMessage:false,
      showDynamicError:false,
      selectedFile:null,
      filename:"",
      userId:"",
    };
    this.onClick = this.onClick.bind(this);
    this.datatest = "";
    this.DeclerationChecked = this.DeclerationChecked.bind(this);
  }
  

  DeclerationChecked(){
    this.setState({
      authorisation: !this.state.authorisation
    });
    //console.log(this.state.energyChecklist);

    if(this.state.authorisation === "Yes")
    {
      this.setState({        decleration: "true"      });      
    }
    else{
      this.setState({        decleration: "false"      });      
    }
    }


  fisheriesOffenceHandler  = cT => () => {

    this.setState({
      hasFisheryOffence: cT
    });
    console.log(cT)

  };


  revokedPermit  = rP => () => {

    this.setState({
      hasHadPermitRefused: rP
    });
    console.log(rP)

    if(this.state.hasHadPermitRefused === "permitRefused_true")
    {
      this.setState({permitRefusedSelectedValue: "true"});
    }
    else{
      this.setState({permitRefusedSelectedValue: "false"});
    }

  };
  supplyRegionCheck  = cT => () => {
    this.setState({
      supplyRegion: cT
    });
  };




  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

changeHandler = event => {
  this.setState({
    [event.target.name]: event.target.value
  });
  console.log(event.target.value);
};

changeHandlerApplicationType = event => {
  this.setState({
    applicationTypeSelected: event.target.value
  });
  console.log(event.target.value);
};


handleFileChange = (event) => {
  this.setState({
    selectedFile: event.target.files[0],
    filename: event.target.files[0].name,
  });
};



  async componentDidMount() {
    // const [formData, setFormData] = useState(FormData);
    $('#processingLoader').hide();
    $('#signUpBtnBilling').show();
    $('#newLoader').hide();
    $('#dynamicError').hide();

    this.setState({userId:this.props.userId});
    var test = this.props.userId;
    console.log(test);

    if(test !== undefined)
    {     

      await axios.get(process.env.REACT_APP_API_ACTIVE_URL + "AllUsers/" + test)
      .then(response => {
        console.log("Data");
        console.log(response.data);
        console.log(response.data.anglerNo);

        this.setState({ 
          anglerNo:response.data[0].anglerNo,
          email:response.data[0].email,
          firstName:response.data[0].firstName,
          surname:response.data[0].surname,
          dateOfBirth:response.data[0].dateOfBirth,
          signupType:response.data[0].signupType,
          address:response.data[0].address,
          street:response.data[0].street,
          country:response.data[0].country,
          postCode:response.data[0].postCode,
          primaryContactNo:response.data[0].primaryContactNo,
          secondaryContactNo:response.data[0].secondaryContactNo,
          city:response.data[0].city,
          memberStatus:response.data[0].memberStatus,
          username:response.data[0].username,
          password:response.data[0].password,
          role:response.data[0].role
        });
    
        console.log("DOB");
        console.log(this.state.dateOfBirth);


        })
        .catch(err => {
          console.log("err");
          console.log(err);
      })


    }
    else
    {
      console.log("Error " + test)
    }
  
    try {
      var checkisDpaMActive = "";
      var dpaMessageText = "";
      //check the data pulled from the api is not empty
          if(this.state.AppOptions.length > 0)
          {
            checkisDpaMActive = this.state.AppOptions[0].optionValue;
            dpaMessageText =  this.state.AppOptions[0].displayMessage;
          }
    }
    catch(err) {
     // window.location.reload();
    }


    if(checkisDpaMActive == "True")
    {
      this.setState(
        {
          showDpaMessage:true,
          dpaMessage:dpaMessageText
        }
      )
    }
    else{
      this.setState(
        {
          showDpaMessage:false,
          dpaMessage:""
        }
      )
    }

        var urlParams = new URLSearchParams(window.location.search);
        var checkLoggedIn = urlParams.get("isLoggedIn");

      if(checkLoggedIn == "true")
      {
        $('#signUp2').hide();
      }
      else
      {
        $('#signUp2').show();
      }
    
          console.log("DOB");
          console.log(this.state.dateOfBirth);
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }



  submitHandler = event => {
    event.preventDefault();
    $('#processingLoader').show();
    $('#signUpBtn').hide();

    var valid = "";
    valid = event.target.reportValidity();


    if(valid === true)
    {
      if(this.state.selectedFile === null)
      {
        alert("Please make sure to include your photo Id");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }

      if(this.state.hasFisheryOffence === undefined)
      {
        alert("Please select weather you have ever had a previous fishery offence");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }

      if(this.state.permitRefusedSelectedValue === "")
      {
        alert("Please select weather you have ever had a permit refused in the past");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }

      
      if(this.state.decleration === "")
      {
        alert("Please confirm you will abide by the company and fishery rules");
        $('#processingLoader').hide();
        $('#signUpBtn').show();
        return null;
      }
  
      const formDataNew = new FormData();
      formDataNew.append('file', this.state.selectedFile);
     
  
        var url =   process.env.REACT_APP_API_ACTIVE_URL + 'UserApplications';  
  
  
        let headersUsed = new Headers();
        headersUsed.append('Content-Type', 'application/json');
        headersUsed.append('Accept', 'application/json');
      
        
        headersUsed.append('Access-Control-Allow-Credentials', 'true');
      
        headersUsed.append('GET', 'POST', 'OPTIONS');
  
  
  
          const formdata = new FormData();
          formdata.append('PhotoId', this.state.selectedFile);
          formdata.append("City", this.state.city);
          formdata.append("Email", this.state.email);
          formdata.append("Surname", this.state.surname);
          formdata.append("PostCode", this.state.postCode);
          formdata.append("FirstName", this.state.firstname);
          formdata.append("AddressOne", this.state.addressOne);
          formdata.append("AddressTwo", this.state.addressTwo);
          formdata.append("DisplayDate", "Pass");
          formdata.append("GuarantorsName", this.state.guarantorsName);
          formdata.append("ApplicationType", this.state.applicationTypeSelected);
          formdata.append("AdditionalDetails", this.state.additionalDetails);
          formdata.append("GuarantorsAddress", this.state.guarantorsAddress);
          formdata.append("GuarantorsPermitNo", this.state.guarantorsPermitNo);
          formdata.append("ContactNumberPrimary", this.state.contactNumberPrimary);
          formdata.append("ContactNumberSecondary", this.state.contactNumberSecondary);
          formdata.append("FisheryOffence", this.state.hasFisheryOffence);
          formdata.append("PermitRefused", this.state.permitRefusedSelectedValue);
          formdata.append("Decleration", this.state.decleration);
          formdata.append("PhotoFileName", this.state.selectedFile.name);
          formdata.append("DateOfBirth", this.state.dateofbirth);
  
          axios.post(url, formdata).then((response) => {
            // handle success
            alert("Success");
          }).catch((error) => {
            // handle error
            console.log("ERROR : " + error);
            alert(error);
            $('#processingLoader').hide();
            $('#signUpBtn').show();
          });
        }
        else{
          $('#processingLoader').hide();
          $('#signUpBtn').show();
        }
        


  };



  async uploadFile(e) {

    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("formFile", file)
    console.log("Data Below 3");   
    console.log(formData);     
         
    
    // this.state.formDataTest = e.target.files[0];
    setTimeout(
      function() {
        console.log(formData.get('formFile'));
        this.setState({formDataTest:formData.get('formFile')})
        console.log(formData.get('formFile'));
      }
      .bind(this),
      2000
    );
      
  }
  handleChangeRole = event => {
    console.log(event.target.value);
    this.setState({role:event.target.value})
    



  };
  

  render() {
    const { applicationTypeSelected } = this.state;
    const { templateMessageCC } = this.state;
    const {uploadPercentage} = this.state; 
    const {userId} = this.state; 


    const { anglerNo } = this.state;
const { email } = this.state;
const { firstName } = this.state;
const { surname } = this.state;
const { dateOfBirth } = this.state;
const { signupType } = this.state;
const { address } = this.state;
const { street } = this.state;
const { country } = this.state;
const { postCode } = this.state;
const { primaryContactNo } = this.state;
const { secondaryContactNo } = this.state;
const { city } = this.state;
const { memberStatus } = this.state;
const { username } = this.state;
const { password } = this.state;
const { role } = this.state;


    const options = [
      {value: '', text: '--Application Type--'},
      {value: 'Adult', text: 'Adult'},
      {value: 'Junior', text: 'Junior'},
      {value: 'Pensioner', text: 'Pensioner'},
    ]

    const roleoptions = [
      {value: '', text: '--Order Type--'},
      {value: 'User', text: 'User'},
      {value: 'Admin', text: 'Admin'},
    ]
    

    return (      
          <div > 
        <header>
  
          <MDBContainer id="signUp2" fluid className="mt-5 mb-5">

          <form
           id="solarform"
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
          >

            <MDBRow>
              <MDBCol size="12">
                <p className="display-7 text-center text-uppercase">{firstName} { surname } Angler N.O : { anglerNo } </p>
                <hr className="divider" />
              </MDBCol>
            </MDBRow>

            {/*Dynamic error*/}
            {
            this.state.showDynamicError?
            <div>
                 <MDBRow>
                   <MDBCol size="3" />
                   <MDBCol lg="6" md="12">
                     <MDBAlert color={this.state.dynamicErrorColor} dismiss>
                       {this.state.dynamicError}
                       </MDBAlert>
                     </MDBCol>
                     <MDBCol size="3" />
                   </MDBRow>
             </div>
            :null            
            } 



            {/* <MDBRow >           
              <MDBCol lg="1" md="12" />
                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={anglerNo}
                    onChange={this.changeHandler}
                    type="text"
                    id="anglerNo"
                    className="form-control"
                    name="anglerNo"
                    maxLength="30"
                    pattern="^[a-zA-Z]*$"
                    placeholder="Angler Number"
                    required
                  />
                </div>
                </MDBCol> 
              </MDBRow>*/}

             <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />
                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.firstName}
                    onChange={this.changeHandler}
                    type="text"
                    id="firstName"
                    className="form-control"
                    name="firstName"
                    maxLength="30"
                    pattern="^[a-zA-Z]*$"
                    placeholder="First Name"
                    required
                  />
                </div>
                </MDBCol>

                <MDBCol lg="5" md="12"  className="mb-3" >
                <div className="dark-grey-text">
                <input
                    value={this.state.surname}
                    onChange={this.changeHandler}
                    type="text"
                    id="surname"
                    className="form-control"
                    name="surname"
                    maxLength="30"
                    pattern="^[a-zA-Z]*$"
                    placeholder="surname"
                    required
                  />
                </div>
                </MDBCol>

              </MDBRow>



              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />


                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                  <input
                    value={this.state.email}
                    onChange={this.changeHandler}
                    type="text"
                    id="email"
                    className="form-control"
                    name="email"
                    maxLength="50"
                    //pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    placeholder="Email Address"
                    required
                  />

                </div>
                </MDBCol>

                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.dateOfBirth}
                    onChange={this.changeHandler}
                    type="text"
                    id="dateOfBirth"
                    className="form-control"
                    name="dateOfBirth"
                    maxLength="50"
                    //pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    placeholder="D.O.B"
                    required
                  />
                  {/* <input
                    value={this.state.dateOfBirth}
                    onChange={this.changeHandler}
                    type="date"
                    id="dateOfBirth"
                    className="form-control"
                    name="dateOfBirth"
                    maxLength="15"
                    pattern="^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}"
                    placeholder="D.O.B"
                    onfocus="(this.type='date')"
                    required
                  /> */}
                </div>
                </MDBCol>
              </MDBRow>

 
              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />
              <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <textarea
                    value={this.state.address}
                    onChange={this.changeHandler}
                    type="textarea"
                    id="address"
                    className="form-control"
                    name="address"
                    //pattern="^[^'<>{};]*$"
                    maxLength="50"
                    placeholder="Address"
                    required
                  />
                </div>
                </MDBCol>

                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.street}
                    onChange={this.changeHandler}
                    type="text"
                    id="street"
                    className="form-control"
                    name="street"
                    maxLength="10"
                    //pattern="^[^'<>{};]*$"
                    placeholder="Street"
                    required
                  />
                </div>
                </MDBCol>
              </MDBRow>


              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />
              <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <textarea
                    value={this.state.country}
                    onChange={this.changeHandler}
                    type="text"
                    id="country"
                    className="form-control"
                    name="country"
                    //pattern="^[^'<>{};]*$"
                    maxLength="50"
                    placeholder="Country g"
                    required
                  />
                </div>
                </MDBCol>

                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.postCode}
                    onChange={this.changeHandler}
                    type="textarea"
                    id="postCode"
                    className="form-control"
                    name="postCode"
                    maxLength="10"
                    //pattern="^[^'<>{};]*$"
                    placeholder="Post Code"
                    required
                  />
                </div>
                </MDBCol>
              </MDBRow>


              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />

              <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                  <input
                    value={this.state.primaryContactNo}
                    onChange={this.changeHandler}
                    type="text"
                    id="primaryContactNo"
                    className="form-control"
                    name="primaryContactNo"
                    maxLength="15"
                    pattern="^[0-9_ ]*$"
                    placeholder="Primary Contact Number"                    
                  />
                </div>
                </MDBCol>

                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                  <input
                    value={this.state.secondaryContactNo}
                    onChange={this.changeHandler}
                    type="text"
                    id="secondaryContactNo"
                    className="form-control"
                    name="secondaryContactNo"
                    maxLength="15"
                    pattern="^[0-9_ ]*$"
                    placeholder="Secondary Contact Number"                    
                  />
                </div>
                </MDBCol>

              </MDBRow>





              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />
                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.city}
                    onChange={this.changeHandler}
                    type="text"
                    id="city"
                    className="form-control"
                    name="city"
                    maxLength="40"
                    pattern="^[a-zA-Z0-9\s]+$"
                    placeholder="City"
                    required
                  />
                </div>
                </MDBCol>

                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.memberStatus}
                    onChange={this.changeHandler}
                    type="text"
                    id="memberStatus"
                    className="form-control"
                    name="memberStatus"
                    maxLength="30"
                    pattern="^[0-9_ ]*$"
                    placeholder="Member Status"
                    required
                  />
                </div>
                </MDBCol>
              </MDBRow>



              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />
                <MDBCol lg="5" md="12" className="mb-3">
                <div className="dark-grey-text">
                <input
                    value={this.state.username}
                    onChange={this.changeHandler}
                    type="text"
                    id="username"
                    className="form-control"
                    name="username"
                    maxLength="40"
                    pattern="^[a-zA-Z0-9\s]+$"
                    placeholder="Username"
                    required
                  />
                </div>
                </MDBCol>

                <MDBCol lg="5" md="12">
              <div className="dark-grey-text">
                <label className="labelCss" for="role">Role</label><br></br>
                <select  required id="role" className="form-select-box titleSelect selectBx orderTypeSelect" value={this.state.role} onChange={this.handleChangeRole}>
                {roleoptions.map(option => (
                  <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
                </div>
                </MDBCol>
              </MDBRow>



          {/*Authorization*/}
          <MDBRow className="optionCssJoinForm mt-3 text-center ">
            <MDBCol lg="12" md="12">            
            <div className="d-flex justify-content-center">
              <p className="photoupldtext text-center text-uppercase">Add an image below if you wish to replace the users profile picture</p>
              </div>
            </MDBCol>
            <MDBCol size="3" />
          </MDBRow>


          <MDBRow id="signUpBtnBilling" className="mb-2">
          {/*Form Left Column*/}
          <MDBCol lg="1" md="12"/>
          <MDBCol lg="10" md="4">
            <div className="d-flex justify-content-center mt-3">
            {/* <input className="photoupldFile text-center text-uppercase"  id="uploadCaptureInputFile" type="file" onChange={this.uploadFile}/> */}
            <input type="file" name="file" onChange={this.handleFileChange} />
            </div>
          </MDBCol>
          </MDBRow>  



              <MDBRow >
              {/*Form Left Column*/}
              <MDBCol lg="1" md="12" />
              <MDBCol lg="10" md="4">
                <div id="signUpBtn" className="d-flex justify-content-center mt-3 mb-5">
                <button type="submit" className="btnFormat" id="submitButton">Send</button>
               
                  
                </div>
              </MDBCol>
              </MDBRow>  

              {
                this.state.showDpaMessage?
                <MDBRow id="signUpBtnBilling">
                {/*Form Left Column*/}
                <MDBCol lg="1" md="12" />
                <MDBCol lg="6" md="4">
                  <div className="d-flex justify-content-center mt-3">
                    <p >{this.state.dpaMessage}</p>
                    
                  </div>
                </MDBCol>
                </MDBRow>  
                :null
              }

          </form>

              <MDBRow id="processingLoader">
              <MDBCol size="4" />
              <MDBCol lg="12" md="4">
                <div className="d-flex justify-content-center">
                    <Loader className="mt-5"
                    type="Puff"
                    color="#3A499A"
                    height={50}
                    width={50}
                    margin-left={50}
                  />                  
                </div>
                <div className="d-flex justify-content-center">Processing</div>
              </MDBCol>
              <MDBCol size="4" />
            </MDBRow>  
            
              


          </MDBContainer>
        </header>
      </div>     
       
    
    );
    
  }
}

export default UserMyDetails;
