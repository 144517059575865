import React, { useState } from 'react';
import {
  MDBRow, MDBCol
} from 'mdbreact';
import Form from '../Components/FormSubmission'

export default function App() {
  return (
    <div >
      <header >
          <Form />

      </header>
    </div>
  );
}