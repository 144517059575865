import React, {useRef} from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBContainer, MDBRow, MDBModal, MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, 
   MDBInput, MDBForm, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardText, MDBAlert,MDBDatePickerV5 
} from 'mdbreact';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader-spinner';
import ViewUser from '../Components/UserFormUpdateDetails';
import AddUser from '../Components/AddNewUser';
import DeleteUser from '../Components/DeleteUser';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// import { postDatatoDb } from '../../components/JSfunctions/Utils';
const FilterableTable = require('react-filterable-table');



class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      name:"",
      address:"",
      eircode:"",
      email:"",
      contact:"",
      productselected:"",
      addednotes:"",
      contactError:false,
      radio: 0,
      location:"",
      dynamicErrorColor:"",
      dynamicError:"",
      dpaMessage:"",
      showDpaMessage:false,
      showDynamicError:false,
      showProductTableroi:false,
      showProductTableni:false,
      thirdPartyDataNi:[],
      thirdPartyDataRoi:[],
      dataPasssed:[],
      userId:"",
      showLoader:true
    };
    this.onClick = this.onClick.bind(this);



  }

  handleDownloadExcel(dataPassed) {

    console.log("handleDownloadExcel");

    // Remove the photoId field from each object in the dataPassed array
    const cleanedData = dataPassed.map(obj => {
        const { photoId, ...rest } = obj; // Destructure photoId and get the rest of the fields
        return rest; // Return an object without the photoId field
    });

    console.log("Data Cleaned");

    // Convert cleanedData to Excel
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "data.xlsx");

    console.log("Export Completed");
};

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }
  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };



  async componentDidMount() {
    
    $('#processingLoader').hide();
    $('#signUpBtnBilling').show();
    $('#newLoader').hide();
    $('#dynamicError').hide();

 

    await axios.get(process.env.REACT_APP_API_ACTIVE_URL + 'AllUsers', {
      timeout: 100000 // Timeout value in milliseconds (5 seconds in this case)
    })
      .then(response => {
        // Handle successful response
        console.log(response.data);
        this.setState({ 
          dataPasssed:response.data,
        });
        if(response.data.length > 0)
        {
          this.setState({showLoader:false})
        }
      })
      .catch(error => {
        // Handle error or timeout
        console.log("err");
        console.log(error);

           this.setState({showLoader:false})
      });


    // axios.get(process.env.REACT_APP_API_ACTIVE_URL + "AllUsers")
    // .then(response => {
    //   console.log(response.data);
    //   this.setState({ 
    //     dataPasssed:response.data,
    //   });
    //   if(response.data.length > 0)
    //   {
    //     this.setState({showLoader:false})
    //   }
    //   })
    //   .catch(err => {
    //     console.log("err");
    //     console.log(err);
    // })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  toggleEditUser = test2 => () => {
    console.log(test2)
    this.setState({
      modalManagerChange: !this.state.modalManagerChange,
      userId:test2
    });
  }
  toggleAddUser = test2 => () => {
    console.log(test2)
    this.setState({
      modalAddUserChange: !this.state.modalAddUserChange
    });
  }

  toggleDeleteUser = test2 => () => {
    console.log(test2)
    this.setState({
      modalDeleteUser: !this.state.modalDeleteUser,
      userId:test2
    });
  }

  render() {
    const {dataPasssed} = this.state; 
    const {userId} = this.state; 
    const iconClassName = "fa fa-edit fa-lg editHover";
    const iconClassNameAdd = "fa-solid fa-user-plus fa-lg editHover sticky-navbar-shadow";


    // const ExcelFile = ReactExport.ExcelFile;
    // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const renderAge = (props) => {
      // If they are over 60, use the "blind" icon, otherwise use a motorcycle
      //const iconClassName = "fa fa-" + (props.value > 0 ? "blind" : "motorcycle");

      const personName = props.record.name;
      console.log("props");
      console.log(props);    
      return (
        <div >
          <MDBRow>
            <MDBCol size="12" className="text-right ">
            <div onClick={this.toggleEditUser(props.value)}  className={iconClassName}></div>
            </MDBCol>
            {/* <MDBCol size="4" className="text-right ">
            <div onClick={this.toggleEditUser()}  className={iconClassNameAdd}></div>
            </MDBCol> */}
          </MDBRow>  
          
        </div>
      );
    };
    const renderImage = (props) => {
      // If they are over 60, use the "blind" icon, otherwise use a motorcycle
      //const iconClassName = "fa fa-" + (props.value > 0 ? "blind" : "motorcycle");

      const personName = props.record.name;
      console.log("props");
      console.log(props);    
      return (
        <div >
          <MDBRow>
            <MDBCol size="12" className="text-right ">
            <img className="profilePicImageSz" src={"data:image/png;base64," + props.value} alt="Image" />
            </MDBCol>
            {/* <MDBCol size="4" className="text-right ">
            <div onClick={this.toggleEditUser()}  className={iconClassNameAdd}></div>
            </MDBCol> */}
          </MDBRow>  
          
        </div>
      );
    };


    const fields = [
      { name: 'id' , thClassName:"fieldCss", displayName: "Actions", inputFilterable: true, sortable: true, someRandomProp: "Tacos!",render: renderAge },
      { name: 'anglerNo' , thClassName:"fieldCss", displayName: "Angler N.O", inputFilterable: true, sortable: true },
      { name: 'firstName' , thClassName:"fieldCss", displayName: "First Name", inputFilterable: true, exactFilterable: true, sortable: true },
      { name: 'surname' , thClassName:"fieldCss", displayName: "Surname", inputFilterable: true, exactFilterable: true, sortable: true },
      { name: 'email' , thClassName:"fieldCss", displayName: "Email", inputFilterable: true, sortable: true },
      // { name: 'dateOfBirth' , thClassName:"fieldCss", displayName: "dateOfBirth", inputFilterable: true, exactFilterable: true, sortable: true },
      { name: 'signupType' , thClassName:"fieldCss", displayName: "Signup Type", inputFilterable: true, exactFilterable: true, sortable: true },
      { name: 'address' , thClassName:"fieldCss", displayName: "Address", inputFilterable: true, sortable: true },
      // { name: 'street' , thClassName:"fieldCss", displayName: "street", inputFilterable: true, exactFilterable: true, sortable: true },
      // { name: 'country' , thClassName:"fieldCss", displayName: "country", inputFilterable: true, exactFilterable: true, sortable: true },
      // { name: 'postCode' , thClassName:"fieldCss", displayName: "postCode", inputFilterable: true, sortable: true },
      { name: 'primaryContactNo' , thClassName:"fieldCss", displayName: "Primary Contact N.O", inputFilterable: true, exactFilterable: true, sortable: true },
      // { name: 'secondaryContactNo' , thClassName:"fieldCss", displayName: "secondaryContactNo", inputFilterable: true, exactFilterable: true, sortable: true },
      // { name: 'city' , thClassName:"fieldCss", displayName: "city", inputFilterable: true, sortable: true },
      { name: 'memberStatus' , thClassName:"fieldCss", displayName: "Member Status", inputFilterable: true, exactFilterable: true, sortable: true },
      // { name: 'username' , thClassName:"fieldCss", displayName: "username", inputFilterable: true, exactFilterable: true, sortable: true },
      { name: 'role' , thClassName:"fieldCss", displayName: "Role", inputFilterable: true, exactFilterable: true, sortable: true },
      { name: 'photoId' , thClassName:"fieldCss", displayName: "Actions", inputFilterable: true, sortable: true, someRandomProp: "Tacos!",render: renderImage },
    ];



    return (      
          <div > 
        <header>

                  
          <MDBModal isOpen={this.state.modalManagerChange} toggle={this.toggleEditUser("Close")} size="lg">
                <MDBRow>
                  <MDBCol size="12" className="text-right ">
                    <i class="exPointer fas fa-times fa-2x pr-5 pt-3 be-blue-text ml-5" onClick={this.toggleEditUser("Close")} style={{ cursor: "pointer" }} />
                  </MDBCol>
                </MDBRow>           
              <ViewUser userId={userId}/>
          </MDBModal>

          <MDBModal isOpen={this.state.modalAddUserChange} toggle={this.toggleAddUser("Close")} size="lg">
                <MDBRow>
                  <MDBCol size="12" className="text-right ">
                    <i class="exPointer fas fa-times fa-2x pr-5 pt-3 be-blue-text ml-5" onClick={this.toggleAddUser("Close")} style={{ cursor: "pointer" }} />
                  </MDBCol>
                </MDBRow>           
              <AddUser />
          </MDBModal>
                  
          <MDBModal className="overlayPos overlayHeight" isOpen={this.state.modalDeleteUser} toggle={this.toggleDeleteUser("Close")} size="fluid">
                <MDBRow>
                  <MDBCol size="12" className="text-right ">
                    <i class="fas fa-times fa-2x pr-5 pt-3 be-blue-text" onClick={this.toggleDeleteUser("Close")} style={{ cursor: "pointer" }} />
                  </MDBCol>
                </MDBRow>           
              <DeleteUser userId={userId}/>
          </MDBModal>

          <MDBRow className="view mb-3  ml-5 mt-5">
           
                <MDBCol size="1" className="addNewUserCss">
                Add New User
                </MDBCol>
                <MDBCol size="1" className="">
                <div onClick={this.toggleAddUser()}  className={iconClassNameAdd}></div>
                </MDBCol>
                <MDBCol size="3" className="">
                <button className="btnFormat" onClick={()=>this.handleDownloadExcel(dataPasssed)}  color="orange" size="md">
                                      <MDBIcon far icon="clone" className="left" /> Export Data </button>
                </MDBCol>

              </MDBRow>
         
          <MDBContainer id="signUp2" fluid className="ContainerOverflow mb-5">
              <FilterableTable
                  namespace="People"
                  initialSort="name"
                  data={dataPasssed}
                  fields={fields}
                  noRecordsMessage="There are no people to display"
                  noFilteredRecordsMessage="No people match your filters!"
                  topPagerVisible ={false}
                  pagerBottomClassName="test"
                />

                
          {
            this.state.showLoader?
            <MDBRow >
            <MDBCol size="4" />
            <MDBCol lg="12" md="4">
              <div className="d-flex justify-content-center">
                  <Loader className="mt-5"
                  type="Puff"
                  color="#3A499A"
                  height={50}
                  width={50}
                  margin-left={50}
                />                  
              </div>
              <div className="d-flex justify-content-center">Please Wait</div>
            </MDBCol>
            <MDBCol size="4" />
          </MDBRow>  
            :null
          }




          </MDBContainer>
        </header>
      </div>     
       
    
    );
    
  }
}



export default UsersTable;
