import React from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBContainer, MDBRow, MDBModal, MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBCol, MDBCardBody, MDBBtn, MDBView, MDBMask, 
   MDBInput, MDBForm, MDBCard, MDBCardHeader, MDBCardTitle, MDBCardText, MDBAlert,MDBDatePickerV5 
} from 'mdbreact';
import Select from 'react-select';
import axios from 'axios';
import $ from 'jquery';
import Loader from 'react-loader-spinner';
import FormSubmissionTable from '../Components/FormSubmissionTable';
// import { postDatatoDb } from '../../components/JSfunctions/Utils';

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      userDetails:[],
      userId:"",
    };
    this.onClick = this.onClick.bind(this);
  }

 
  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }
  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };



  async componentDidMount() {
    
    $('#processingLoader').hide();
    $('#signUpBtnBilling').show();
    $('#newLoader').hide();
    $('#dynamicError').hide();

    var x = localStorage.getItem("currentUser");
    var obj = JSON.parse(x);

    console.log("obj View Applications");
    console.log(obj);

    if(obj !== null)
    {
      //Set Details
      this.setState({ 
        userDetails:obj,
      });
      
    }
    else{
      window.location.href = "/Login";
    }




  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }



  render() {
    const { userDetails } = this.state;
    return (      
          <div className="mb-5  pb-5"> 
        <header>
  
          <MDBContainer fluid className="mt-5 mb-5">
            <MDBRow className="">
                <MDBCol size="12" className="full-img text-center">
                <p className="Heading-One display-7 text-center text-uppercase">Faughan Angler Applications </p>
                <hr className="divider" />
                <p className="display-7 text-center text-uppercase">Please find the list of applications below</p>
                </MDBCol>
              </MDBRow>


            <MDBRow className="view sticky-navbar-shadow">
                <MDBCol size="12" className="full-img text-center">
                <FormSubmissionTable/>
                </MDBCol>
              </MDBRow>
          </MDBContainer>
        </header>
      </div>     
       
    
    );
    
  }
}

export default UserTable;
