import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

class FooterTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNI:false,
      decleraton:'',

    };
  }
 

  componentDidMount() {
    const loc = window.location;
    var page = 'footer';




    if (loc.href.includes("beenergy")) {
      this.setState({
        showNI:false,
        decleraton:'A DCC Business. Registered Office: Knockbrack House, Matthews Lane, Donore Road, Drogheda, County Louth. Budget Energy Limited is a subsidiary of Flogas Ireland Limited. Company No: 496676. © Copyright BE Energy 2020'
      })
    }
    else {
      this.setState({
        showNI:true,
        decleraton:'A DCC Business. Registered Office: 4 - 48 Airport Road West, Belfast, Down, Northern Ireland, BT3 9ED. Budget Energy Limited is a subsidiary of Flogas Ireland Limited. Company No: NI 073739. © Copyright Budget Energy 2020'
      })
    }
  }


  deleteConsent()
  {
    this.setCookie("cookieconsent", "false", -365);

    window.location.reload();
  }




  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
  
    
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";


  }


  render() {
    return (
      <MDBFooter color=" darken-5" className="font-small pt-4 mt-0 footer-position">
        <MDBContainer className="mb-3" fluid>
          <MDBRow>
            <MDBCol size="12" className="text-center">
              <h5 className="title text-uppercase">Follow us on social media</h5>
              <p>
                <a className="Footer-Link-Facebook" href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square fa-3x pr-3" /></a>
                <a className="Footer-Link-Twitter" href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square fa-3x pr-3" /></a>
                <a className="Footer-Link-LinkedIn" href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-3x pr-3" /></a>
                <a className="Footer-Link-Instagram" href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-3x pr-3" /></a>
              </p>
      
            </MDBCol>
          </MDBRow>

        {/*Footer Links*/}
        {/* <MDBRow>
          <MDBCol size="12" className="text-center text-uppercase">
            <ul id="footer">
              <li id="footer">
                <a href="/about">About Us</a>
              </li>
              <li className="orange-text font-weight-bold">|</li>
              <li id="footer">
                <a href="/about/termsandconditions">Terms & Conditions</a>
              </li>
              <li className="orange-text font-weight-bold">|</li>
              <li id="footer">
                <a href="/help">Tariff FAQ's</a>
              </li>
              <li className="orange-text font-weight-bold">|</li> 
              <li id="footer">
                <a href="/about/codesofpractice">Codes of Practice</a>
              </li>
              <li className="orange-text font-weight-bold">|</li>
              {this.state.showNI?
                <li id="footer">
                <a href="/help/techQuery?techQuery=EnergyTheft">Energy Theft</a>
              </li>
                :null
              }       
              {this.state.showNI?
                <li className="orange-text font-weight-bold">|</li>
                :null
              }           
              <li id="footer">
                <a href="/about/GDPR">Privacy Centre</a>
              </li>
              <li className="orange-text font-weight-bold">|</li>
              <li id="footer">
                <a href="/help/tarifftable">Tariff Table</a>
              </li>
              <li className="orange-text font-weight-bold">|</li>
              <li id="footer">
                <a href="/about/antislavery">Anti Slavery</a>
              </li>
              <li className="orange-text font-weight-bold">|</li> 
              <li id="footer">
                <a onClick={()=>this.deleteConsent()}>Manage Cookies</a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow> */}
        </MDBContainer>

        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid className="text-uppercase">
            
            &copy; {new Date().getFullYear()} Copyright: <a className="Footer-Link-Copyright" href="https://www.FaughanAnglers.co.uk">
              <strong className="Footer-Text-Copyright"> FaughanAnglers.co.uk</strong> 
              <p className="versionText">version: {process.env.REACT_APP_VERSION}</p>
            </a>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}

export default FooterTest;
